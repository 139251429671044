import { Observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Gains } from 'api/zlApi/types'
import ObjectDisplayer from 'components/shared/ObjectDisplayer'
import useApiCallStore from 'hooks/useApiCallStore'

import Link from '../Link'

import styles from './index.module.scss'

const LatestTaxCalculation = () => {
  const { userId } = useParams() as { userId: string }
  const store = useApiCallStore()

  return (
    <Observer>
      {() => {
        const [loading, setLoading] = useState(true)

        const { latestTaxCalculation } = store

        useEffect(() => {
          store
            .getLatestTaxCalculation(userId)
            .catch(console.warn)
            .finally(() => setLoading(false))
        }, [])

        if (loading) return null

        if (!latestTaxCalculation) return <div>No tax calculation found.</div>

        const { gains_by_year: gainsByYear } = latestTaxCalculation as {
          gains_by_year: { [_: number]: Gains }
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <div style={{ overflow: 'auto', flexGrow: 1 }}>
              <h4>Latest Tax Calculation:</h4>
              <ObjectDisplayer object={latestTaxCalculation} />
            </div>
            <h5>Get tax year summary for:</h5>
            <div className={styles.links}>
              {Object.keys(gainsByYear).map((year) => (
                <Link
                  key={year}
                  to={`/api_testing/tax_calculations/${latestTaxCalculation.id}/years/${year}/summary`}
                >
                  {year}
                </Link>
              ))}
            </div>
          </div>
        )
      }}
    </Observer>
  )
}

export default LatestTaxCalculation
