import React from 'react'
import { CodeBlock, googlecode } from 'react-code-blocks'

import Link from '../Link'

import code from './code'
import types from './types'

const StartPage = () => (
  <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
    <div style={{ flexGrow: 1, overflow: 'auto' }}>
      <h2>Below is the typescript code to interact with our api.</h2>
      <p>
        You can find the types below which describe the api responses then you can see the api
        handlers below the types file.
      </p>
      <h3>types.ts</h3>
      <CodeBlock language="typescript" theme={googlecode} text={types} />
      <h3 style={{ marginTop: '1rem' }}>api.ts</h3>
      <CodeBlock language="typescript" theme={googlecode} text={code} />
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', background: 'white' }}>
      <div style={{ flexGrow: 1 }} />
      <Link to="users">Try the users api</Link>
      <div style={{ paddingRight: '1rem' }} />
    </div>
  </div>
)

export default StartPage
