const types = `export interface JwtTokenResponse {
  access_token: string
  token_type: string
  expires_in: number
  scope: string
  created_at: number
}

export interface PaginatedResponse {
  meta: {
    page: number
    page_size: number
    total_count: number
  }
}

export interface TimeStampedRecord {
  created_at: string
  updated_at: string
}

export interface User extends TimeStampedRecord {
  id: string
  email: string | null
  time_zone: string
}

export interface UsersResponse extends PaginatedResponse {
  users: User[]
}

export interface Gains {
  staked_reward?: number | string
  short_term?: number | string
  long_term?: number | string
}

export interface TaxCalculation extends TimeStampedRecord {
  id: string
  calculated: boolean
  gains_by_year: {
    [year: number]: Gains
  } | null
}

export interface LatestTaxCalculationResponse {
  tax_calculation: TaxCalculation
}

export interface TaxYearSummaryResponse {
  tax_year_summary: Gains
}

export interface TaxableEvent {
  digest: string
  buy_date: string | null
  sell_date: string
  currency: string
  quantity_sold: number | string
  proceeds: number
  cost_basis: number
}

export interface PaginationParams {
  page: number
  page_size?: number
}

export interface TaxableEventsResponse extends PaginatedResponse {
  taxable_events: TaxableEvent[]
}

export interface LatestTaxCalculationParams {
  userId: string
}

export interface TaxYearSummaryParams {
  taxCalculationId: string
  year: number
}

export type TaxableEventsParams = TaxYearSummaryParams & PaginationParams
`
export default types
