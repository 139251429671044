const code = `import axios, { AxiosRequestHeaders, AxiosRequestTransformer } from 'axios'

import env from 'env' // this should be everywhere your env variables or secrets are.

import {
  JwtTokenResponse,
  LatestTaxCalculationParams,
  LatestTaxCalculationResponse,
  PaginationParams,
  TaxableEventsParams,
  TaxableEventsResponse,
  TaxYearSummaryParams,
  TaxYearSummaryResponse,
  UsersResponse,
} from './types'

// DO NOT send CLIENT_ID or CLIENT_SECRET to users or the frontend.
// These values are meant to be used server side to create jwt tokens.
const { CLIENT_ID, CLIENT_SECRET } = env

const BASE_URL = env.ZENLEDGER_BASE_URL // https://api.zenledger.io for production
const V1_BASE_URL = \`\${BASE_URL}/partners/api/v1\`

let jwtToken: string | null = null
let tokenExpiresAt: Date | null = null

// Create an api service that injects the temporary (30 minute) jwt token.
const apiService = axios.create({
  transformRequest: [
    (data: unknown, headers: AxiosRequestHeaders) => {
      // eslint-disable-next-line no-param-reassign
      headers.Authorization = \`Bearer \${jwtToken}\`
      return data
    },
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

// Treat this like you would CLIENT_ID and CLIENT_SECRET since it grants access to all user data.
const getJwtToken = async (): Promise<JwtTokenResponse> => {
  const body = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    grant_type: 'client_credentials',
  }
  const { data } = await axios.post<JwtTokenResponse>(\`\${BASE_URL}/oauth/token\`, body)
  return data
}

// this code wraps a given api promise with code that evaluates if you need
// to refresh your credentials and automatically handles that.
const wrapApiCall = <
  T extends (...args: never[]) => Promise<unknown>,
  U extends Awaited<ReturnType<T>>,
  V extends (...args: Parameters<T>) => Promise<U>,
>(
  func: T,
): V => {
  const wrapped = async (...args: Parameters<T>): Promise<U> => {
    if (!jwtToken || (tokenExpiresAt && tokenExpiresAt < new Date())) {
      const now = new Date()
      const { access_token: accessToken, expires_in: expiresIn } = await getJwtToken()
      jwtToken = accessToken
      now.setSeconds(now.getSeconds() + (expiresIn * 2) / 3)
      tokenExpiresAt = now
    }

    return (await func(...args)) as U
  }

  return wrapped as V
}

// getUsers fetches a list of all the users associated to your account.
export const getUsers = wrapApiCall(async (params: PaginationParams): Promise<UsersResponse> => {
  const { data } = await apiService.get<UsersResponse>(\`\${V1_BASE_URL}/users\`, { params })
  return data
})

// getLatestTaxCalculation fetches the latest tax calculation for a given user.
export const getLatestTaxCalculation = wrapApiCall(
  async ({ userId }: LatestTaxCalculationParams) => {
    const {
      data: { tax_calculation: taxCalculation },
    } = await apiService.get<LatestTaxCalculationResponse>(
      \`\${V1_BASE_URL}/users/\${userId}/user_tax_calculations/latest\`,
    )
    return taxCalculation
  },
)

// getTaxYearSummary gets a summary for a given year and given tax calculation.
export const getTaxYearSummary = wrapApiCall(
  async ({ taxCalculationId, year }: TaxYearSummaryParams) => {
    const {
      data: { tax_year_summary: taxYearSummary },
    } = await apiService.get<TaxYearSummaryResponse>(
      \`\${V1_BASE_URL}/tax_calculations/\${taxCalculationId}/years/\${year}/summary\`,
    )
    return taxYearSummary
  },
)

// getTaxableEvents gets all the taxable events for a given tax calculation and year.
export const getTaxableEvents = wrapApiCall(
  async ({ taxCalculationId, year, ...params }: TaxableEventsParams) => {
    const { data } = await apiService.get<TaxableEventsResponse>(
      \`\${V1_BASE_URL}/tax_calculations/\${taxCalculationId}/years/\${year}/events\`,
      { params },
    )
    return data
  },
)
`

export default code
